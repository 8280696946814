<template>
  <div>
    <b-card no-body>
      <b-tabs pills class="pt-1 pl-1 pr-1">
        <b-tab
            title="Dashboard"
            active
            @click="$router.push('dashboard')"
        />
        <b-tab
            title="Apps"
            @click="$router.push('apps')"
        />
        <b-tab
            title="Stats"
        />
        <b-tab
            title="Docs"
        />
        <b-tab
            title="Plans"
        />
        <b-tab
            title="Notifications"
        />
      </b-tabs>

    </b-card>

    <b-card title="Kick start your project 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>Please make sure to read our <b-link
        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text>
    </b-card>

    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import {BCard, BCardText, BLink, BTab} from 'bootstrap-vue'
import { BNav, BTabs, BNavItem, } from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BNav,
    BNavItem,
    BTabs,
    BTab
  },
  mounted() {
    this.$http.get("/user/me").then(response=>{
      this.$store.commit("app/UPDATE_USERDATA",response.data);
    })
  }
}
</script>

<style scoped>

</style>
